import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Testimonial from "../sections/index/indexreviews.js";
import Contentchin from "../sections/jaw/jawcontent.js";
import GalleryChin from "../sections/chin/chingallery.js";
import ChinFaq from "../sections/jaw/jawfaq.js";
import Head from "../components/head"
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/chinjawfillers.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const Jaw = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
    <Head title="Jaw Filler Treatment in London" image="/images/dermamina-home.jpg"  description="Experts in Jaw Filler treatments London" keywords="Jaw Filler London, Jaw Filler, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Jaw </span> <span style="color: #00aec7;">Filler</span>'
    secondText="Define, restore and contour, to create your ideal profile"
    videoAlt="Jaw Filler"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Jaw Filler"
  resultstext="12 - 18 months"
  resultsalt="Jaw Filler results"
  costdata="From £340"
  costalt="Jaw Filler Cost"
  timedata="10 - 15 Minutes"
  timealt="Jaw Filler Duration Time"
  workdata="Immediately"
  workalt="Jaw Filler downtime work"
  paindata="None / Mild"
  painalt="Jaw Filler Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<GalleryChin />
<Award />
<Testimonial />
<Contentchin
  setIsModalOpen={setIsModalOpen}
/>
<ChinFaq
  setIsModalOpen={setIsModalOpen}
/>
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default Jaw;
